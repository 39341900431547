// TODOSE automatic script which copies prisma enums?
// TODOSE wenn das hier nicht ausreicht, prisma importe von db.server checken!
// zB import type { language } from "./services/db.server";

export enum CompanyAddressType {
  Loading = "Loading",
  Delivery = "Delivery",
  Billing = "Billing",
}

export enum NotificationType {
  default = "default",
}

export enum TagType {
  String = "String",
  Number = "Number",
}

export enum UserTradeProductStatusType {
  Open = "Open",
  PartialAcceptance = "PartialAcceptance",
  FullAcceptance = "FullAcceptance",
  Closed = "Closed",
  Expired = "Expired",
}

export enum UserTradeProductDeliveryType {
  NotSet = "NotSet",
  Self = "Self",
  Connect8 = "Connect8",
}

export enum PriceHistoryCreatorType {
  Creator = "Creator",
  Bidder = "Bidder",
}

export enum LoginType {
  User = "User",
  InternalEmployee = "InternalEmployee",
  Company = "Company",
}

export enum TaskType {
  CompanyActivation = "CompanyActivation",
  JoinConnect8 = "JoinConnect8",
  Bug = "Bug",
  DevelopmentRequest = "DevelopmentRequest",
  GeneralRequest = "GeneralRequest",
  BillingIssue = "BillingIssue",
  PartnerRequest = "PartnerRequest",
  JobRequest = "JobRequest",
}

export enum InvoiceState {
  Open = "Open",
  Invoiced = "Invoiced",
  Payed = "Payed",
  Problem = "Problem",
}

export enum InvoicePositionType {
  Trade = "Trade",
  Delivery = "Delivery",
}

export enum InvoiceStepStatus {
  None = "None",
  Stripe_Done = "Stripe_Done",
  DMS_Upload_Done = "DMS_Upload_Done",
  All_Done = "All_Done",
}
